import * as React from "react"
import * as PropTypes from "prop-types"
// @ts-ignore
import ContainerStyles from "../styles/container.module.scss"
import { ReactNode } from "react"

const Container = ({children}: { children: ReactNode }) => (
  <div className={ContainerStyles.container}>
    {children}
  </div>
)

export default Container