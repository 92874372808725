import * as React from "react"
import * as PropTypes from "prop-types"
import { graphql, Link, useStaticQuery } from "gatsby"
// @ts-ignore
import FooterStyles from "../styles/footer.module.scss"
import Container from "./container"

type FooterProps = {
  author: string,
  title: string
}

const Footer = ({author, title}: FooterProps) => (
  <footer>
    <Container>
      <div className={FooterStyles.content}>
        <div className={FooterStyles.contentCopyrightDisclaimer}>
          <div className={FooterStyles.disclaimer}>
            {title} is neither endorsed, affiliated with nor approved by any state,
            multi-state lottery operator or organization whatsoever.
            All trademarks remain the property of their rightful owners and used for informational purposes only.
          </div>
          <div className={FooterStyles.copyright}>© {new Date().getFullYear()} {author}</div>
        </div>
        <div className={FooterStyles.contentLegal}>
          <Link
            to='/privacy-policy'
            className={`${FooterStyles.contentLegal} ${FooterStyles.contentLegal__link}`}
          >
            Privacy policy
          </Link>
          <Link
            to='/terms-of-service'
            className={`${FooterStyles.contentLegal} ${FooterStyles.contentLegal__link}`}
          >
            Terms of Service
          </Link>
          <Link
            to='/disclaimer'
            className={`${FooterStyles.contentLegal} ${FooterStyles.contentLegal__link}`}
          >Disclaimer</Link>
        </div>
      </div>
    </Container>
  </footer>
)

// Note
Footer.propTypes = {
  author: PropTypes.string,
  title: PropTypes.string
}

export default Footer