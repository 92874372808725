/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from "react"
import * as PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
// @ts-ignore
// TODO: why is this causing ts errors
import LayoutStyles from "../styles/layout.module.scss"
import Footer from "./footer"

type LayoutProps = {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title,
          author
        }
      }
    }
  `)

  return (
    <div className={LayoutStyles.layout}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className={LayoutStyles.content}>
        {children}
      </main>
      <Footer author={data.site.siteMetadata.author}
              title={data.site.siteMetadata.title}/>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
