import { Link } from "gatsby"
import * as PropTypes from "prop-types"
import * as React from "react"
// @ts-ignore
import HeaderStyles from "../styles/header.module.scss"
import Container from "./container"

const Header = ({ siteTitle }) => (
  <header className={HeaderStyles.menu}>
    <Container>
      <div className={HeaderStyles.banner}>
        <div>
          <Link
            className={HeaderStyles.banner__title}
            to="/"
          >
            <h2> {siteTitle}</h2>
          </Link>
        </div>
        <div>
          <nav className={HeaderStyles.banner__navList}>
            <Link
              to="/"
              className={HeaderStyles.banner__navItem}>
              <h3>Home</h3>
            </Link>
            <Link
              to="/faq"
              className={HeaderStyles.banner__navItem}>
              <h3>Faq</h3>
            </Link>
          </nav>
        </div>
      </div>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
